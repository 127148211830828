<template>
    <div>
        <el-dialog :visible="supplementModalLocal" title="Begär komplettering">
            <el-row>
                <el-col :span="16">
                    <el-input resize="none" :rows="11" type="textarea" placeholder="Kommentar" v-model="comment" />
                </el-col>
                <el-col :span="8">
                    <el-scrollbar :height="200">
                        <div class="flex flex-col space-y-2 items-stretch">
                            <el-tooltip v-for="(commentTemplate, index) in commentTemplates" :key="index" class="box-item" effect="dark" :content="commentTemplate.tooltip" placement="left-end">
                                <el-button class="ml-4" type="primary" @click="comment = commentTemplate.content">{{ commentTemplate.title }}</el-button>
                            </el-tooltip>
                        </div>
                    </el-scrollbar>
                </el-col>
            </el-row>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeSupplementModal">Avbryt</el-button>
                    <el-button type="primary" @click="sendSupplement">
                        Skicka
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: ["supplementModal", "discardModal", "denyModal"],
    watch: {
        supplementModal: {
            handler() {
                this.supplementModalLocal = this.supplementModal;
            },
            immediate: true,
        },
    },
    data: function() {
        return {
            comment: "",
            commentTemplates: [
                {
                    title: "Datumen stämmer inte överens",
                    tooltip: "Datumet i bilagan stämmer inte överens med datum på transaktionen",
                    content: "Datumet i bilagan stämmer inte överens med datum på transaktionen",
                },
                {
                    title: "Beloppen stämmer inte överens",
                    tooltip: "Beloppet i bilagan stämmer inte överens med beloppet på transaktionen",
                    content: "Beloppet i bilagan stämmer inte överens med beloppet på transaktionen",
                },
            ],
            supplementModalLocal: false,
        };
    },
    methods: {
        closeSupplementModal() {
            this.$emit("closeSupplementModal");
        },
        sendSupplement() {
            this.$emit("supplement", this.comment);
        },
    },
};
</script>
